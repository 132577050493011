import { lazy } from 'react';
import ReactDOM from 'react-dom/client';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const blocks: { [name: string]: any } = {
  root: lazy(() => import('../xpack/root/Root')),
  gridLayout: lazy(() => import('../xpack/root/GridLayout')),
  header: lazy(() => import('./organisms/header/Header')),
  businessCalculatorResult: lazy(() => import('./organisms/business-rates-calculator/BusinessCalculatorResult')),
  businessCalculatorResultDetail: lazy(() => import('./organisms/business-rates-calculator/BusinessCalculatorResultDetail')),
  stampDutyCalculatorDetail: lazy(() => import('./organisms/stamp-duty-calculator/StampDutyCalculatorResultDetail')),
  peopleSearch: lazy(() => import('./organisms/people-search/PeopleSearch')),
  featuredContent: lazy(() => import('./organisms/header/FeaturedContent')),
  tinyMce: lazy(() => import('./organisms/optimizely/TinyMCE')),
  dropdown: lazy(() => import('./molecules/dropdown-field/DropdownField')),
  leaseExtensionCalculator: lazy(() => import('./organisms/lease-extension-calculator/LeaseExtensionCalculator')),
  propertySearch: lazy(() => import('./organisms/property-search/PropertySearch')),
  shareOverlay: lazy(() => import('./organisms/share-overlay/ShareOverlay')),
};

const loadComponent = (scriptSection: HTMLScriptElement) => {
  const blockType = scriptSection.getAttribute('data-rct');
  const data = scriptSection.textContent ? scriptSection.textContent : '{}';

  if (!blockType || !data) {
    return;
  }

  const Component = blocks[blockType];
  if (Component) {
    scriptSection.textContent = null;
    const section = document.createElement(scriptSection.getAttribute('data-tag') ?? 'section');
    section.className = scriptSection.getAttribute('data-class') ?? '';
    scriptSection.replaceWith(section);

    const props = JSON.parse(data);
    ReactDOM.createRoot(section).render(<Component {...props} />);
  } else {
    return <></>;
  }
};

const loadComponentSSR = async (section: HTMLSelectElement) => {
  const blockType = section.getAttribute('data-rct');
  let data = section.dataset.json;
  const script = section.querySelector('script');
  if (script) {
    data = script.textContent ?? undefined;
    script.remove();
  }
  section.removeAttribute('data-rct');
  section.removeAttribute('data-json');

  if (!blockType) {
    return;
  }

  const Component = await blocks[blockType];
  if (Component) {
    const props = JSON.parse(data ?? '{}');
    ReactDOM.createRoot(section).render(<Component {...props} />);
  } else {
    return <></>;
  }
};

const loadComponents = () => {
  // rct stands for 'react component type'
  const scriptSections = document.querySelectorAll('script[data-rct]');

  [].forEach.call(scriptSections, loadComponent);

  const sections = document.querySelectorAll('section[data-rct]');

  [].forEach.call(sections, loadComponentSSR);
};

loadComponents();
window.addEventListener('load', () => loadComponents());
window.addEventListener('calculatorReload', () => loadComponents());
